@import url("https://fonts.googleapis.com/css2?family=MuseoModerno:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.cdnfonts.com/css/euclid-circular-a");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  --montserrat-font: "Montserrat", sans-serif;
  --museoModerno-font: "MuseoModerno", sans-serif;
  --jost-font: "Jost", sans-serif;
  --open-sans: "Open Sans", sans-serif;
}


html {
  scroll-behavior: smooth;
}

@font-face {
  font-family: "Museo";
  src: url("/public/fonts/museo/Museo300-Regular.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Museo";
  src: url("/public/fonts/museo/Museo700-Regular.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("/public/fonts/euclid-circular-a/Euclid\ Circular\ A\ Regular.ttf")
    format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("/public/fonts/euclid-circular-a/Euclid\ Circular\ A\ Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Euclid Circular A";
  src: url("/public/fonts/euclid-circular-a/Euclid\ Circular\ A\ Bold.ttf")
    format("truetype");
  font-weight: 700;
  font-style: bold;
}

/* FONTS */

.font-mont {
  font-family: "Montserrat";
}

.font-museo {
  font-family: "Museo";
}

.font-jost {
  font-family: "Jost", sans-serif;
}

.font-euclid {
  font-family: "Euclid Circular A";
}

/* BACKGROUNDS */

.bg-primary1 {background-color: #2e3192;}
.bg-secondary1 {background-color: #1e4ca4;}
.bg-tertiary1 {background-color: #259fde;}
.bg-book {background: linear-gradient(90deg, #1e4ca4 0%, #a7c2ff 100%);}
.bg-outer {
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(
      180deg,
      #ebf1ff 2.19%,
      #ffffff 40.45%,
      #ffffff 77.71%,
      #ebf1ff 99.38%
    );
}

.bg-card1 {background-color: #df4132;}
.bg-card2 {background-color: #ffe9e9;}
.bg-card3 {background: #ecf2ff;}
.bg-arrow {background-color: #ce2c31;}
.bg-star {background-color: #ffa500;}
.bg-icon {background-color: #407bff;}
.icon-color {background-color: rgba(54, 59, 71, 0.4);}
.btn-color {background-color: #ce2c31;}
.bg-select {background-color: #183d83;}
.bg-box {background-color: #cee0ff;}
.bg-benefit {background: linear-gradient(90deg, #1e4fa6 0%, #0b234e 100%);}
.band-gradient {background: linear-gradient(90deg, #1e4fa6 0%, #0b234e 100%);}
.bg-grad {background: linear-gradient(180deg,#ebf1ff 2.19%,#ffffff 40.45%,#ffffff 77.71%,#ebf1ff 99.38%);}

/* TEXT COLOR */

.font {color: #f3f3ff;}
.font1 {color: #1e4fa6;}
.font2 {color: #505050;}
.font3 {color: #81878c;}
.font4 {color: #2e3192;}
.font5 {color: #0b234e;}
.font6 {color: #df4132;}
.font7 {color: #666363;}
.font8 {color: #a01d30;}
.font9 {color: #747272;}
.font10 {color: #81878c;}

/* BORDERS */

.border-color1 {border: #df4132 1px solid;}
.border-color2 {border: #2e3192 1px solid;}
.border-colorb {border-bottom: #2e3192 1px solid;}
.border-color3 {border: #747272 2px solid;}
.border-d {border-bottom: 1px dashed #81878c;}

/* HOVERS */
.hover-color1:hover {background-color: #cf2516;}
.hover-color2:hover {background-color: #2e3192;}
.hover-color3:hover {background-color: #1e4fa6;}
.hover-color4:hover {background: linear-gradient(90deg, #1e4fa6 0%, #0b234e 100%);}

/* BORDER RADIUS */

@media (min-width: 1024px) and (max-width: 1024px) {
  .logout-button {margin-top: 130px !important;}
}

@media (min-width: 1440px) and (max-width: 1440px) {
  .logout-button {margin-top: 320px !important;}
}

.otp-timer-text,.otp-timer-button {font-family: "Montserrat";font-size: 14px;font-weight: 400;color: #2e3192;font-style: normal;padding: 0;}

/* Add these styles to your CSS file or inline using a <style> tag */
.terms-content {font-size: 1rem;color: #333;line-height: 1.6;}
.terms-content ul {list-style-type: disc;padding-left: 1.5rem;}

.terms-content a {
  color: blue; /* Ensures links are blue */
  text-decoration: underline; /* Optional: underlines links */
}
.terms-content h1 {
  font-size: 2rem; /* Adjust as needed */
  color: #333; /* Customize text color */
  margin-bottom: 1rem; /* Space below the heading */
}

.terms-content h2 {
  font-size: 1.5rem; /* Adjust as needed */
  color: #333; /* Customize text color */
  margin-bottom: 0.75rem; /* Space below the heading */
}

.form-control:focus {
  border: none;
  box-shadow: none; /* Removes the box shadow (focus effect) */
}

.drp-hero-slider .swiper-button-next,
.drp-hero-slider .swiper-button-prev,
.check-offers-slider .swiper-button-next,
.check-offers-slider .swiper-button-prev {width: 32px;height: 32px;display: block;border-radius: 50%;background-color: #fff;z-index: 11;background-size: 24px;background-position: center;background-repeat: no-repeat;top: 50%;transform: translateY(-50%);}
.drp-hero-slider .swiper-button-next::after,
.drp-hero-slider .swiper-button-prev::after,
.check-offers-slider .swiper-button-next::after,
.check-offers-slider .swiper-button-prev::after {display: none;}
.drp-hero-slider .swiper-button-next,
.check-offers-slider .swiper-button-next {
  right: 25px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuMjkgOC4xMkwxMy4xNyAxMkw5LjI5IDE1Ljg4QzguOSAxNi4yNyA4LjkgMTYuOSA5LjI5IDE3LjI5QzkuNjggMTcuNjggMTAuMzEgMTcuNjggMTAuNyAxNy4yOUwxNS4yOSAxMi43QzE1LjY4IDEyLjMxIDE1LjY4IDExLjY4IDE1LjI5IDExLjI5TDEwLjcgNi43QzEwLjMxIDYuMzEgOS42OCA2LjMxIDkuMjkgNi43QzguOTEgNy4wOSA4LjkgNy43MyA5LjI5IDguMTJaIiBmaWxsPSIjMUU0Q0E0Ii8+Cjwvc3ZnPgo=");
}
.drp-hero-slider .swiper-button-prev,
.check-offers-slider .swiper-button-prev {
  left: 25px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjcxIDguMTJMMTAuODMgMTJMMTQuNzEgMTUuODhDMTUuMSAxNi4yNyAxNS4xIDE2LjkgMTQuNzEgMTcuMjlDMTQuMzIgMTcuNjggMTMuNjkgMTcuNjggMTMuMyAxNy4yOUw4LjcxIDEyLjdDOC4zMiAxMi4zMSA4LjMyIDExLjY4IDguNzEgMTEuMjlMMTMuMyA2LjdDMTMuNjkgNi4zMSAxNC4zMiA2LjMxIDE0LjcxIDYuN0MxNS4wOSA3LjA5IDE1LjEgNy43MyAxNC43MSA4LjEyWiIgZmlsbD0iIzFFNENBNCIvPgo8L3N2Zz4K");
}
.check-offers-slider .swiper-button-prev {left: 4px;}
.check-offers-slider .swiper-button-next {right: 4px;}

.truncate-3-lines {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ql-container {  
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
}

.test-html-content .ql-container .ql-editor .ql-align-justify {
  text-align: left !important;
}

/* Snow Theme */
.ql-editor {min-height: 8em;}
.ql-snow.ql-toolbar {display: block;background: #eaecec;border-top-left-radius: 0.5em;border-top-right-radius: 0.5em;}
.test-html-content .ql-editor p, .test-html-content .ql-editor ul li {font-family: "Montserrat" !important;font-size: 1rem !important;line-height: 1.75rem !important;font-weight: 500 !important;color: #666363;}
.test-html-content .ql-editor h1, .test-html-content .ql-editor h2, .test-html-content .ql-editor h3 {font-family: "Museo" !important;line-height: 2rem !important;font-weight: 600 !important;}
.test-html-content .ql-editor h1,.test-html-content .ql-editor h2,.test-html-content .ql-editor h3 {font-size: 1.5rem !important;color: #2e3192 !important;}
.test-html-content .ql-editor h2 {font-size: 1.3rem !important;color: #2e3192 !important;}
.test-html-content .ql-editor h3 {font-size: 1.25rem !important;}
.test-html-content .ql-editor a {color:#0d6efd;}

#mobile-banner .swiper-button-prev,
#mobile-banner .swiper-button-next {display: none !important;}

.disabled-btn{opacity: 0;}

.bg-card-gradient{background: linear-gradient(260.33deg, rgba(255, 255, 255, 0.5) 0%, rgba(7, 66, 121, 0.43) 94.44%);}
.custom-select-arrow{cursor: pointer;outline: none;appearance: none;background-repeat: no-repeat;background-size: 24px;background-position: center right 4px;background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNSAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzc4M18zODc1IiBzdHlsZT0ibWFzay10eXBlOmFscGhhIiBtYXNrVW5pdHM9InVzZXJTcGFjZU9uVXNlIiB4PSIwIiB5PSIwIiB3aWR0aD0iMjUiIGhlaWdodD0iMjUiPgo8cmVjdCB4PSIwLjYzMTgzNiIgeT0iMC42ODM1OTQiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iI0Q5RDlEOSIvPgo8L21hc2s+CjxnIG1hc2s9InVybCgjbWFzazBfNzgzXzM4NzUpIj4KPHBhdGggZD0iTTEyLjYzMTggMTUuMzQ0OUMxMi41MTEzIDE1LjM0NDkgMTIuMzk5MiAxNS4zMjU3IDEyLjI5NTMgMTUuMjg3MkMxMi4xOTE1IDE1LjI0ODkgMTIuMDkyNyAxNS4xODI5IDExLjk5OTEgMTUuMDg5Mkw3LjUwNDgyIDEwLjU5NUM3LjM2NjQ4IDEwLjQ1NjUgNy4yOTMxNSAxMC4yODQ5IDcuMjg0ODIgMTAuMDgwNEM3LjI3NjQ4IDkuODc2MTIgNy4zNDk4MiA5LjY5NjM3IDcuNTA0ODIgOS41NDEyQzcuNjQ5ODIgOS4zOTYzNyA3LjgyNTQ4IDkuMzIzOTUgOC4wMzE4MiA5LjMyMzk1QzguMjM4MTUgOS4zMjM5NSA4LjQxMzgyIDkuMzk2MzcgOC41NTg4MiA5LjU0MTJMMTIuNjMxOCAxMy41OTg5TDE2LjcwNDggOS41NDEyQzE2Ljg0MzMgOS40MDI3IDE3LjAxNDggOS4zMjkyOCAxNy4yMTkzIDkuMzIwOTVDMTcuNDIzOCA5LjMxMjYyIDE3LjYwMzcgOS4zODYwMyAxNy43NTg4IDkuNTQxMkMxNy45MDM3IDkuNjg2MDMgMTcuOTc2MSA5Ljg2MTYyIDE3Ljk3NjEgMTAuMDY3OUMxNy45NzYxIDEwLjI3NDQgMTcuOTAzNyAxMC40NTAxIDE3Ljc1ODggMTAuNTk1TDEzLjI2NDYgMTUuMDg5MkMxMy4xNzA5IDE1LjE4MjkgMTMuMDcyMiAxNS4yNDg5IDEyLjk2ODMgMTUuMjg3MkMxMi44NjQ1IDE1LjMyNTcgMTIuNzUyMyAxNS4zNDQ5IDEyLjYzMTggMTUuMzQ0OVoiIGZpbGw9IiMxQzFCMUYiLz4KPC9nPgo8L3N2Zz4K");}
.bg-gragient-healthPackages{
  /* background: linear-gradient(246.5deg, #FFFFFF 0%, rgba(30, 79, 166, 0) 94.44%); */
  background-image: url('../public/images/gradient-bg.png');
  background-position: center;background-repeat: no-repeat;
  background-size: cover;
}

#reviews-prev-btt.swiper-button-disabled,
#reviews-next-btt.swiper-button-disabled {
  /* opacity: 0.5 !important; */
  background-color: #D9D9D9 !important;
  border-color: #D9D9D9 !important;
  cursor: not-allowed !important;
}


.bg-book-your-test{
    background-image: url('../public/images/book-test-img.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 800px;
}
