@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=ABeeZee:ital@0;1&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Expletus+Sans:ital,wght@0,400..700;1,400..700&family=Inter:wght@100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Nunito:ital,wght@0,200..1000;1,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Roboto+Slab:wght@100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sen:wght@400..800&family=Vazirmatn:wght@100..900&display=swap");
@import url("https://fonts.cdnfonts.com/css/euclid-circular-a");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

/* ------------------ADMIN CSS ----------------------*/

.navbar {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.nav-admin img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.nav-admin span {
  font-family: "Poppins";
  font-weight: 500;
  color: #292d32;
  text-decoration: none;
}

.top-barcolumn h5 {
  font-size: 20px;
  margin-bottom: 10px;
  margin-left: -10px;
}

.radio-formbox {
  display: flex;
}
.radio-formbox .form-check {
  margin: 0 5px;
}
.radio-formbox input {
  cursor: pointer;
}
.radio-formbox label {
  font-weight: 500 !important;
  color: rgb(109, 109, 109);
}

.admin-dashbox {
  background-color: #fff;
  padding: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 15px;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

/* .admin-banner {
  margin-left: 20%;
  padding: 10%;
} */

.admin-dashbox h2 {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 500;
}
.admin-dashbox h3 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.admin-dashbox p {
  font-size: 12px;
  color: #545454;
  font-family: "Poppins";
  font-style: italic;
}

.dash-space {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dash-img {
  width: 80px;
  height: 80px;
}

.dash-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.overlapping-avatar {
  list-style-type: none;
  margin: 15px 0;
  padding: 0;
  display: flex; /* Use flexbox to align items */
  align-items: center;
}

.overlapping-avatar li {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid white;
  display: flex; /* Flexbox for centering content */
  align-items: center;
  justify-content: center;

  position: relative;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
}

.overlapping-avatar li img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  vertical-align: 0% !important;
}

.overlapping-avatar li span {
  font-family: "Poppins";
  font-weight: 500;
  text-align: center;
}

.overlapping-avatar li:nth-child(1) {
  background-color: #259fde;
}
.overlapping-avatar li:nth-child(2) {
  background-color: #26a4e3;
}
.overlapping-avatar li:nth-child(3) {
  background-color: #4db8ee;
}
.overlapping-avatar li:nth-child(4) {
  background-color: #33b1f1;
}
.overlapping-avatar li:nth-child(n + 2) {
  margin-left: -25px;
}

.overlapping-avatar:hover li:nth-child(n + 2) {
  margin-left: -10px;
}

.dash-recent {
  background-color: #fff;
  padding: 3%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 15px;
}

.recents,
.sales {
  border-radius: 10px;
  border: 1px solid #d9d9d9;
  padding: 5%;
}

.recents h5 {
  font-size: 20px;
  font-family: "Poppins";
  margin-bottom: 20px;
}

.recent-tile {
  border: 1px solid #d9d9d9;
  border-left: 3px solid #d9d9d9;
  margin-bottom: 3%;
  display: flex;
  align-items: center;
  padding: 5px;
}

.recent-tile span {
  padding-right: 20px;
  padding-left: 10px;
  border-right: 1px solid #d9d9d9;
  font-size: 15px;
  font-weight: 500;
  font-family: "Poppins";
}

.recent-tile p {
  margin-bottom: 0;
  padding-left: 10px;
  font-size: 15px;
  font-family: "Poppins";
}

.sales-1 {
  border: 1px solid #ffce5b;
  border-radius: 10px;
  padding: 5%;
  background: #fffaee;
  margin-bottom: 5%;
  font-family: "Poppins";
}

.sales-2 {
  border: 1px solid #589bf8;
  background: #e3eaf4;
  border-radius: 10px;
  padding: 5%;
  font-family: "Poppins";
}

.sales-1 h5,
.sales-2 h5 {
  font-size: 20px;
  font-weight: 500;
}

.sales-1 i,
.sales-2 i {
  font-size: 20px;
  margin-right: 10px;
  color: #410101;
}

.sales-1 h6,
.sales-2 h6 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
}

.sales-1 span {
  font-size: 12px;
  color: #d80000;
}
.sales-2 span {
  font-size: 12px;
  color: rgb(31, 168, 31);
}

.sales-1 p,
.sales-2 p {
  font-size: 13px;
  margin-bottom: 0;
  color: gray;
  font-style: italic;
}

.recent-sale {
  display: flex;
  justify-content: space-between;
  font-family: "Poppins";
  padding: 4%;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 3%;
}

.recent-sale span {
  padding: 15px;
  border-radius: 6px;
  background: #38a3a525;
  color: #259fde;
  margin-right: 10px;
}

.recent-sale h6 {
  font-size: 16px;
  margin-bottom: 0;
  /* padding-top: 10px; */
}
.recent-sale h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  /* padding-top: 10px; */
}

.recent-sale p {
  margin-bottom: 0;
  font-size: 13px;
}

/* =============== Sidebar =============== */
.sidebar {
  position: fixed;
  top: 10;
  left: 0;
  height: 100%;
  width: 260px !important;
  background: #ecf2ff;
  color: #000;
  transition: all 0.5s ease;
  z-index: 100;
  font-family: "Poppins";
}

.sidebar.close {
  width: 78px !important;
}

.logout {
  position: absolute;
  bottom: 2%;
  padding-top: 5px;
  border-top: 1px solid #d9d9d9;
  width: 100%;
}

/* --------- Logo ------------ */

.logo-box {
  /* height: 60px; */
  width: 100%;
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
}

.logo-box i {
  font-size: 30px;
  height: 50px;
  min-width: 78px;
  text-decoration: none;
  color: #259fde;
  text-align: center;
  line-height: 50px;
  transition: all 0.5s ease;
  margin-left: -20px;
}
.sidebar.close .logo-box i {
  transform: rotate(180deg);
}

/* ---------- Sidebar List ---------- */
.sidebar-list {
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto !important;
}

.sidebar-list::-webkit-scrollbar {
  display: none;
}

.sidebar-list li {
  transition: all 0.5s ease;
}

.sidebar-list li .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.5s ease;
  cursor: pointer;
}

.sidebar-list li.active .title {
  background-color: #259fde;
  color: white;
}

.sidebar-list li.active .bi-chevron-down {
  transition: all 0.5s ease;
  transform: rotate(180deg);
}

.sidebar-list li .title .link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.sidebar-list li .title i {
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #292d32;
  font-size: 20px;
  margin-left: -20px;
}

.sidebar-list li .title .name {
  font-size: 14px;
  font-weight: 400;
  color: #0c0c0d !important;
}

/* ---------------- Submenu ------------- */
.sidebar-list li .submenu {
  display: none;

  width: 0;
  height: 0;
  opacity: 0;
  transition: all 0.5s ease;
}

.sidebar-list li.active .submenu {
  width: unset;
  height: unset;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 6px 6px 14px 80px;
  background-color: #eeeeee;
}

.sidebar-list li.dropdown.active .submenu {
  width: unset;
  height: unset;
  opacity: 1;
  display: flex;
  flex-direction: column;
  padding: 6px 6px 14px 80px;
  background-color: #eeeeee;
}

.submenu .link {
  color: #000;
  font-size: 15px;
  padding: 5px 0;
  transition: all 0.5s ease;
  text-decoration: none;
}

.submenu-title {
  display: none;
}

/* ---------------- Submenu Close ------------- */
.sidebar.close .logo-name,
.sidebar.close .title .name,
.sidebar.close .title .bi-chevron-down {
  display: none;
}

.sidebar.close .sidebar-list {
  overflow: visible;
}

.sidebar.close .sidebar-list li {
  position: relative;
}

.sidebar.close .sidebar-list li .submenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  height: max-content;
  width: max-content;
  opacity: 0;
  transition: all 0.5s ease;
  pointer-events: none;
  color: #000;
}

.sidebar.close .sidebar-list li:hover .submenu {
  opacity: 1;
  top: 0;
  pointer-events: initial;
  background-color: #a952a3;
}

.sidebar.close .submenu-title {
  display: block;
  font-style: 18px;
}

.maintable-column {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 5px;
  margin-left: 0px;
  padding: 20px;
}
.foot-tablerow h5 {
  font-size: 25px;
  margin: 15px -15px;
  color: #545454;
}

.heading-and-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
}

#datatable {
  width: 100% !important;
  table-layout: auto !important;
  border-collapse: collapse;
  margin-top: 15px;
}

.table select {
  width: 100px;
  height: 30px;
}

#datatable th {
  font-weight: 500;
  font-size: 17px;
  color: #666666;
  border-bottom: 1px solid #f1f1f1;
  padding: 12px !important;
  text-align: left;
  background-color: transparent; /* Ensures header background is white */
}

#datatable td:first-child {
  text-align: center;
}

#datatable th:first-child {
  text-align: center;
}

#datatable td {
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #666666;
  border-bottom: 1px solid #f7f7f7;
  padding: 15px 10px !important;
  text-align: left;
  word-wrap: break-word;
  background: transparent !important;
}

#datatable td .td-span {
  padding: 3px 16px;
  border: 0.5px solid #38a3a5;
  border-radius: 20px;
  background: #38a3a565;
  color: #000;
}

#datatable thead tr {
  background-color: #f7f1f7;
}
#datatable tbody tr:hover {
  background-color: #f7f7f7 !important;
  cursor: pointer;
}
#datatable tbody tr:nth-child(even) {
  background-color: #f7f8f7;
}

#datatable tbody tr:nth-child(odd) {
  background-color: white;
}

.fa-pen-to-square {
  padding: 5px;
  color: #38a3a5;
  border: 0.5px solid #38a3a5;
  border-radius: 5px;
  cursor: pointer;
}

.fa-trash {
  padding: 5px;
  color: #d80000;
  border: 0.5px solid #d80000;
  border-radius: 5px;
  cursor: pointer;
}

.user-eye {
  padding: 5px;
  color: green;
  border: 0.5px solid green;
  border-radius: 5px;
  cursor: pointer;
}

.table-banner {
  margin-left: 17% !important;
  /* padding: 5%; */
}
.dt-column-order {
  display: none !important;
}

.home {
  position: relative;
  padding-left: 5%;
  padding-top: 2%;
  left: 230px;
  width: calc(100% - 260px);
  height: 100vh;
  transition: all 0.5s ease;
}

.sidebar.close ~ .home {
  left: 78px;
  width: calc(100% - 78px);
}

.home .toggle-sidebar {
  height: 60px;
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
}

.home .toggle-sidebar i {
  font-size: 25px;
  margin-left: 15px;
}

.home .toggle-sidebar .text {
  font-size: 25px;
  font-weight: 600;
}

/* LOGO */

.logo-columnimg {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 5px;
  margin-left: 40px;
}

.logo-insidebox {
  padding: 30px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 5px;
  margin-bottom: 15px;
}
.logo-columnimg button {
  margin: 20px 0;
}

/* NAVBAR */

.btn-success {
  background: #38a3a5 !important;
  color: white !important;
  border: 1px solid #38a3a5 !important;
}

.btn-success:hover {
  background: white !important;
  color: #38a3a5 !important;
}

.nav-column button {
  margin: 20px;
  background: #38a3a5 !important;
  color: white !important;
}
.nav-column label {
  font-weight: 600;
  color: #545454;
}
.nav-column {
  background-color: #fff;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 5px;
  margin: 20px 40px;
}
.nav-column form {
  padding: 10px 20px;
}

.season_content {
  border: 1px solid rgb(239, 239, 239);

  padding: 10px 20px;
  border-radius: 5px;
}
.season_content span {
  animation: 0.5s ease-out 0s 1 slideInFromTop;
}
.hometab-column {
  background-color: #fff;
  border-radius: 10px;
  display: flex;
}
.season_content select {
  width: 150px;
}
.season_content button {
  margin-top: 15px;
}
.season_content h5 {
  font-size: 16px;
  color: #666;
}
.tab {
  width: 30%;
}

.tab button {
  display: block;
  background-color: #e9fdfd;
  color: black;
  padding: 10px 40px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  border-radius: 10px;
  margin-bottom: 2%;
}

.tab button:hover {
  background-color: #38a3a5;
  color: white;
}

.tab button.active {
  background-color: #38a3a5;
  color: white;
}

.tabcontent {
  display: none;
  padding: 0px 12px;
}

.tabcontent.active {
  display: block;
  border: none;
  width: 70%;
}

.p-block {
  margin-top: 2%;
  margin-bottom: 2%;
}

.p-field label {
  font-family: "Euclid Circular A", sans-serif;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 2%;
}

.p-group,
.p-field select {
  border-radius: 2px;
  border: 1px solid #ababab;
  width: 100%;
}
.p-field select {
  border-radius: 2px;
  border: 1px solid #ababab;
  width: 100%;
  padding: 3%;
}

.p-control {
  border: 0 !important;
  background: white;
  box-shadow: none !important;
}

.p-field .p-prepend span {
  border: 0;
  background: white;
  color: #656565;
  border-radius: 2px;
}

.p-group input::placeholder,
.p-field option,
.p-field select {
  font-family: "Euclid Circular A", sans-serif;
  font-size: 12px;
  font-weight: 400;
  color: #656565;
}

.text-editor-container {
  width: 100%;
  margin-bottom: 5%; /* Adjust as necessary */
}

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 0 !important;
}

.ql-formats button {
  margin: 5px !important;
  background: transparent !important;
}

.login {
  background: #ecf2ff;
  /* display: flex; */
  min-height: 100vh;
}

.login-right {
  box-shadow: 0px 4px 45px 0px #00000040;
  background: #ffffff;
  border-radius: 10px 0px 0px 10px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding: 5%;
  min-height: 100vh;
}

.login-right form {
  width: 100%;
}

.img-login {
  display: flex;
  justify-content: center;
}

.img-login img {
  width: 100%;
}

.login-item {
  margin-bottom: 3%;
}

.login-item label {
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #1e1e1e;
  margin-bottom: 2%;
}

.login-item input {
  width: 100%;
  border: 1px solid #747272;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #747272;
  padding: 2%;
}

.login-item input:focus {
  outline: none;
}

.login-right h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  color: #2e3192;
}

.login-right p {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  line-height: 23.64px;
  text-align: center;
  color: #747272;
}

.forgot p {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #2e3192;
  font-style: normal;
}

.login-button button {
  width: 100%;
  padding: 10px;
  background-color: #2e3192;
  border: 2px solid #2e3192;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  border-radius: 10px;
  margin-top: 4%;
}

.login-button button:hover {
  background-color: transparent;
  color: #2e3192;
  transition: all 0.3s ease-in-out;
}

.login-group {
  width: 100%;
}

.login-group input {
  border-right: 0 !important;
  border: 1px solid #747272;
  border-radius: 10px;
}

.login-group input:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #747272;
}

.login-append span {
  border-left: 0 !important;
  border: 1px solid #747272;
  border-radius: 0px 10px 10px 0px;
  height: 100%;
  margin-top: 0;
  background: transparent;
}

.modal-title.h4 {
  font-size: 20px !important;
  font-family: "Poppins";
  font-weight: 600;
  color: #d80000;
}

.modal-content1 p {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #410101;
}

.modal-footer-btn {
  padding: 5px 20px;
  background: #d80000;
  border: 1px solid #d80000;
  border-radius: 6px;
  color: #fff;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 16px;
}

.userImage {
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
}

.userImage span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  overflow: hidden;
}

.userImage span img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.dash-up {
  margin-bottom: 20px;
}

.dash-strip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #259fde;
  border-radius: 10px;
  padding: 2%;
  position: relative;
  z-index: 10;
}

.over {
  width: 98%;
  margin: 0 auto;
  padding: 2%;
  background: #4b828342;
  margin-top: -25px;
  border-radius: 10px;
  z-index: 2;
}

.dash-strip h3 {
  font-size: 20px;
  margin-bottom: 10px;
  font-family: "Poppins";
  font-weight: 500;
}

.dash-strip p {
  margin-bottom: 0;
  font-style: 14px;
  font-family: "Poppins";
}

.dash-strip i {
  font-size: 30px;
  cursor: pointer;
}

.congrats {
  background: #1a92e836;
  box-shadow: 0px 4px 25px 0px #00000040;
  border-radius: 10px;
  padding: 4%;
  margin-top: 4%;
  margin-bottom: 4%;
}
.profile {
  margin-top: 0%;
}

.profile h5 {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  padding-top: 15px;
}

.profile-header p {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #636060;
}

.profile-header span {
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 400;
  line-height: 16px;
  color: #747272;
  padding: 2px 14px;
  border: 1px solid #636060;
  border-radius: 25px;
  cursor: pointer;
}

.profile-icon {
  display: flex;
  align-items: center !important;
  justify-content: center;
}

.profile-item {
  margin-top: 3%;
}

.profile-item label {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 7px;
  text-align: left;
  color: #747272;
}

.profile-item input {
  width: 100%;
  border-radius: 10px;
  font-family: "Poppins";
  font-size: 14px;
  padding: 2%;
}

.profile-item input:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid gray;
}

.profile-item p:first-child {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #747272;
}

.profile-item p:last-child {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #000;
}
.congrats-button button {
  padding: 8px 35px;
  border-radius: 25px;
  background: #2e3192;
  border: 1px solid #2e3192;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 33px;
  text-align: center;
}

.congrats-button button:hover {
  background: transparent;
  color: #2e3192;
  transition: all 0.3s ease-in-out;
}

.profile-icon img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

/*----------------------------- STOCK FORECAST CSS--------------------------------- */

#datatable-2 {
  width: 100% !important;
  table-layout: auto !important;
  border-collapse: collapse;
  margin-top: 15px;
}

#datatable-2 th {
  font-weight: 500;
  font-size: 15px;
  color: #666666;
  border-bottom: 1px solid #f1f1f1;
  padding: 8px !important;
  text-align: left;
  background-color: transparent; /* Ensures header background is white */
}

#datatable-2 td:first-child {
  text-align: center;
}

#datatable-2 th:first-child {
  text-align: center;
}

#datatable-2 td {
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #666666;
  border-bottom: 1px solid #f7f7f7;
  padding: 13px 8px !important;
  text-align: left;
  word-wrap: break-word;
  background: transparent !important;
}

#datatable-2 td .td-span {
  padding: 3px 16px;
  border: 0.5px solid #2e3192;
  border-radius: 20px;
  background: #38a3a565;
  color: #000;
}

#datatable-2 thead tr {
  background-color: #f7f1f7;
}
#datatable-2 tbody tr:hover {
  background-color: #f7f7f7 !important;
  cursor: pointer;
}
#datatable-2 tbody tr:nth-child(even) {
  background-color: #f7f8f7;
}

#datatable-2 tbody tr:nth-child(odd) {
  background-color: white;
}

.stock-select {
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  border-radius: 5px;
  padding: 5%;
}

.stock-select p {
  font-size: 14px;
  font-weight: 500;
  font-family: "Poppins";
}

.stock-select select {
  width: 100%;
  padding: 3%;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  font-family: "Poppins";
  font-size: 12px;
}

.stock-tabs {
  padding: 2%;
}

.stock-btn {
  padding: 6px 20px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: transparent;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 500;
  margin-right: 2%;
}

.stock-btn:hover {
  background: #d9d9d9;
}

.stock-btn.stock-btn-active {
  background: #d9d9d9;
}

.add-button {
  display: flex;
  justify-content: end;
}

.add-button button {
  padding: 8px 35px;
  background: #2e3192;
  border: 1px solid #2e3192;
  border-radius: 10px;
  color: #fff;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
}
.add-button2 button {
  padding: 8px 35px;
  background: #2e3192;
  border: 1px solid #2e3192;
  border-radius: 10px;
  color: #fff;
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
}

/*----------------------------- STOCK FORECAST CSS--------------------------------- */

@media (max-width: 560px) {
  .logo-columnimg {
    margin-left: 0;
  }
  .dash-column {
    text-align: center;
  }
  .nav-column {
    margin: 10px 0;
  }
  .top-barcolumn h5 {
    margin: 15px 0;
  }
  .foot-column {
    margin: 0;
  }
  .foot-column2 {
    margin: 0;
    height: auto;
  }
  .text-editcolumn {
    margin-left: 5px;
  }
  .maintable-column {
    margin-left: 0;
  }
  .add_testcontent {
    margin-left: 0;
  }
}

/* -------------------ADMIN CSS END--------------------- */
